<template>
  <div class="auth">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <h4 class="card-title bordered">TRADESK</h4>
              <template v-if="! invalid && ! reset">
                <h5 class="mb-4 text-center text-secondary">Checking your account</h5>
                <p class="text-center">Please Wait...</p>
              </template>
              <div v-if="invalid && ! reset" class="text-center">
                <div class="alert alert-danger text-center">
                  We could not find your account. Contact us on support@tradesk.co.ke
                </div>
              </div>
              <template v-if="reset && ! invalid">
                <h5 class="mb-4 text-center text-secondary">Recover your account</h5>
                <form @submit.prevent="submit">
                  <div class="alert alert-danger" v-if="invalid_password">Invalid password</div>
                  <div class="form-group">
                    <label for="email">Create a new password</label>
                    <input
                      type="password"
                      placeholder="******"
                      class="form-control has-error"
                      v-model="user.password"
                      required
                    />
                  </div>
                  <div class="form-group">
                    <label for="email">Repeat your new password</label>
                    <input
                      type="password"
                      placeholder="******"
                      class="form-control has-error"
                      v-model="user.password_confirmation"
                      required
                    />
                  </div>
                  <div class="form-group text-right">
                    <button class="btn btn-theme">
                      <i class="lni-user mr-2"></i> Recover my account
                    </button>
                  </div>
                </form>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      invalid: false,
      reset: false,
      user: {},
      invalid_password: false
    }
  },

  methods: {
    fetch() {
      this.invalid = false
      this.reset = false
      let data = { token: this.$route.params.id }

      this.$axios.post(`/api/v1/check-account`, data).then(() => {
        this.reset = true
        this.invalid = false
      }).catch(() => {
        this.invalid = true
        this.reset = false
      })
    },

    submit() {
      let data = {
        token: this.$route.params.id,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation
      }

      this.invalid_password = false
      this.$axios.post('/api/v1/recover-account', data).then(response => {
        this.$auth.setToken(response.data.access_token)
        window.location.href = this.$router.resolve({ name: 'dashboard' }).href
      }).catch(() => {
        this.invalid_password = true
      })
    }
  }
}
</script>
